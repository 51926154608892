import React, {Component} from 'react';
import CookieConsent from "react-cookie-consent";
import {FormattedMessage, IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import Stronger from './components/sections/stronger';
import VirusEstonia from './components/sections/virus-estonia';
import VirusSpread from './components/sections/virus-spread';
import VirusSpreadHow from './components/sections/virus-spread-how';
import VirusSymptoms from './components/sections/virus-symptoms';
import Prevention from './components/sections/prevention';
import VirusDanger from './components/sections/virus-danger';
import MiddleBuyNow from './components/sections/middle-buy-now';
import StayHealthy from './components/sections/stay-healthy';
import VitaminIllness from './components/sections/vitamin-illness';
import Zinc from './components/sections/zinc';
import Bioflavonoids from './components/sections/bioflavonoids';
import WhyChoose from './components/sections/why-choose';
import YoutubeVideos from './components/sections/youtube-videos';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const {locale} = this.props;
        return (
            <IntlProvider onError={() => {
            }} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale}/>
                    <TopBanner locale={locale}/>
                    <Stronger locale={locale}/>
                    <VirusEstonia/>
                    <VirusSpread locale={locale}/>
                    <VirusSpreadHow/>
                    <VirusSymptoms/>
                    <Prevention/>
                    <VirusDanger/>
                    <MiddleBuyNow/>
                    <StayHealthy/>
                    <VitaminIllness locale={locale}/>
                    <Zinc/>
                    <Bioflavonoids/>
                    <WhyChoose locale={locale}/>
                    <YoutubeVideos/>
                     <Specialists/>
                    <Share/>
                    <Footer locale={locale}/>
                    <CookieConsent contentClasses="cookie-content" buttonText ={<FormattedMessage id="cookie.button"/>}>
                        <FormattedMessage id="cookie"/>
                    </CookieConsent>
                </div>
            </IntlProvider>
        );
    }
}


export default Page;
