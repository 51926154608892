import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/vitamiin-d3-pro-expert-4000-iu-immuno-olikapslid-n60">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank" href="https://www.benu.ee/tooted/vaata-koiki-soodustooteid/pro-expert-vitamiin-d-immuno-olikapslid-4000iu-n60">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/vitamin-d3-pro-expert-4000iu-immuno-kapslid-n60-pmm0188955ee">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/vitamin-d3-pro-expert-4000iu-immuno-n60-olikapslid">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/vitamin-d3-pro-expert-4000iu-immuno-kapslid-n60-pmm0188955ee">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
